(function () {
  var sections = Array.from(document.getElementById('sections').children);
  var navItems = document.getElementById('nav-items');

  function getIndexOfItem(item) {
    var index = 0;
    var currItem = item;

    while (currItem.previousElementSibling) {
      index++;
      currItem = currItem.previousElementSibling;
    }

    return index;
  }

  navItems.addEventListener('click', function (e) {
    var li = e.target;

    if (li.tagName === 'LI') {
      var indexLi = getIndexOfItem(li) || 0;

      sections.forEach(function (section, index) {
        section.className = 'section' + (index === indexLi ?  ' active' : '');
      })
    }
  })
})();
